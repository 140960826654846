import React from 'react'
import ScanMartStore from './Scan-mart-store/ScanMartStore'


const NearMe = () => {
  return (
    <>
      <ScanMartStore />
 
    </>
  )
}

export default NearMe