import React, { useState } from "react";
import "./Online_Store.css";
import { Rating } from "react-simple-star-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Online_Store({ image, heading, city, distanceLocation }) {
  const [rating, setRating] = useState(0);

  const handleRating = (rate: number) => {
    setRating(rate);
  };

  return (
    <>
      <div className="online-store text-center">
        <div className="pt-4">
          <div className="store-holder">
            <img src={image} className="store-img" />
          </div>
          <h2 className="mt-4">{heading}</h2>
          <div className="start-holder">
            <Rating
              onClick={handleRating}
              initialValue={rating}
              fillColor="#f99a1a"
              className="react-simple-star-rating start"
              SVGclassName="star-svg"
            />
          </div>
          <div className="location-holder my-1">
            <FontAwesomeIcon
              icon="fa-solid fa-location-dot"
              className="location-icon me-1"
            />
            <span>{city}</span>
          </div>
          <p>{distanceLocation}</p>
        </div>
      </div>
    </>
  );
}

export default Online_Store;
