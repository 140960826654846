import React, { useState, useEffect, useContext } from "react";
import "./Faq.css";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";


import { getFaqs } from "../../utils/apis/master/Master";
const Faq = () => {


  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [faq, setFaq] = useState([]);
  const getFaq = async () => {
    const res = await getFaqs();
    if (res?.success) {
      setFaq(res.data);
    }
  }

  useEffect(() => {
    getFaq();
  }, []);

  return (
    <>
      <section className="faq">
        <Container>
          <Row>
            <Col md={11} className="mx-auto">
              <div className="faq-banner">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/Image/faq/faq-banner.png"
                  }
                  className="faq-img"
                />
              </div>
            </Col>
            <Col md={10} className="mx-auto">
              <div className="faq-holder ">
                <h1>FAQs</h1>
                <h2 className="mb-4">Get Solutions Here</h2>
                <p>
                  Kindly check the FAQ below if you are not very familiar with
                  the functioning of this website. If your query is of urgent
                  nature and is different from the set of questions then please
                  contact us at: waytozofficial@gmail.com
                </p>
              </div>
              {faq?.map((value, faqindex) => (
                <>
                  <div className="registration-box mt-5">
                    <h2>{value?.name}</h2>
                  </div>
                  <Accordion >
                    {value?.faq?.map((accords, index) => (
                      <Accordion.Item eventKey={faqindex + index}>
                        <Accordion.Header>{accords?.name}</Accordion.Header>
                        <Accordion.Body>
                          <p> {htmlToReactParser.parse(accords?.description)}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}

                  </Accordion>
                </>
              ))}
              {/* <div className="registration-box mt-5">
                <h2>Registration</h2>
              </div>
              <Accordion defaultActiveKey={0}>
                {accordfirst.map((accords) => (
                  <Accordion.Item eventKey={accords.eventskeys}>
                    <Accordion.Header>{accords.headings}</Accordion.Header>
                    <Accordion.Body>
                      <p> {accords.bodytext}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}

              </Accordion> */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Faq;
