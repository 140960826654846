import React, { useContext, useEffect, useState } from "react";
import './AddAddress.css'
import Select from "react-select";
import { Breadcrumb, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import {
  faLocation,
  faLocationDot,
  faPen,
  faPhone,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Successfull_Modal from "../../Common_modal/Successfull_Modal/Successfull_Modal";
import Edit_Address_Modal from "../../Common_modal/Edit_Address_Modal/Edit_Address_Modal";
import Delete_Address from "../../Common_modal/Delete_Address/Delete_Address";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { DeleteAddress, getAddress, getSingleAddress, postAddress } from "../../../utils/apis/orderProcess/orderProcess";
import { Context } from "../../../utils/context";
import { City, Country, Pincode, State } from "../../../utils/apis/master/Master";

function AddAddress() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  const [modalShow, setModalShow] = useState(false);
  const [modalDeleteAddressShow, setModalDeleteAddressShow] = useState(false);
  const [modalEditAddressShow, setModalEditAddressShow] = useState(false);
  const hideModalWithDelay = () => {
    setTimeout(() => {
      setModalShow(false);
    }, 3000); // Change the delay time as needed (3000 milliseconds = 3 seconds)
  };
  const options = [
    { value: "Home", label: "Home" },
    { value: "Work", label: "Work" },
    { value: "Office", label: "Office" },
  ];


  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    setValue,
  } = useForm();

  const [addId, setAddId] = useState("");
  const [addressId, setAddressId] = useState("");
  const [addressData, setAddressData] = useState("");
  const [address, setAddress] = useState([]);
  const addressGet = async () => {
    const res = await getAddress();
    if (res?.success) {
      console.log(res);
      setAddress(res?.data);
      if (!addressId) {
        setAddressId(res?.data[0]?.id);
        setAddressData(res?.data[0]);
      }

    }
  }


  const addressDelete = async (id) => {
    const res = await DeleteAddress(id);
    if (res?.success) {
      setModalDeleteAddressShow(true);
      setTimeout(() => {
        setModalDeleteAddressShow(false);
      }, 2000);
      addressGet();
      reset();
      setValue('address_type', "");
      setValue('name', "");
      setValue('flat_home_bldg', "");
      setValue('col_land_strt', "");
      setValue('mobile_number', "");
      setValue('mobile_number', "");
    setValue('country_id',"");
    setValue('state_id', "");
    setValue('pincode_id',"");
    setValue('address_type', "");
    setValue('id', "");
    }
  }

  const { Select2Data } = useContext(Context);
  const [countries, setCountries] = useState();
  const [states, setState] = useState();
  const [cities, setCity] = useState();
  const [pincodes, setPincode] = useState();
  const getCountries = async () => {
    const res = await Country();
    if (res?.success) {
      const data = await Select2Data(res?.data, 'country_id', false);
      setCountries(data);
    }
  }
  const getState = async (id) => {
    const res = await State(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, 'state_id', false);
      setState(data);
      setValue('state_id', "");
      setValue('city_id', "");
      setValue('pincode_id', "");
    }
  }
  const getCity = async (id) => {
    const res = await City(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, 'city_id', false);
      setCity(data);
      setValue('city_id', "");
      setValue('pincode_id', "");
    }
  }
  const getPincode = async (id) => {
    const res = await Pincode(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, 'pincode_id', false);
      setPincode(data);
      setValue('pincode_id', "");
    }
  }

  const handleEdit = async (id) => {
    const singleEdit = await getSingleAddress(id);
    reset(singleEdit?.data);
    setValue('city_id', { value: singleEdit?.data?.city?.id, label: singleEdit?.data?.city?.name });
    setValue('country_id', { value: singleEdit?.data?.country?.id, label: singleEdit?.data?.country?.name });
    setValue('state_id', { value: singleEdit?.data?.state?.id, label: singleEdit?.data?.state?.name });
    setValue('pincode_id', { value: singleEdit?.data?.pincode?.id, label: singleEdit?.data?.pincode?.name });
    setValue('address_type', { value: singleEdit?.data?.address_type, label: singleEdit?.data?.address_type });
    setValue('id', singleEdit?.data?.id);

    console.log("Singledata", singleEdit?.data);
    // reset(singleEdit?.data)

  }


  const handleAddress = async (id, data) => {
    console.log(id);
    await setAddressId(id);
    await setAddressData(data);
  }

  const onSubmit = async (data) => {
    console.log(data);

    const sendData = {
      address_type: data?.address_type?.value,
      name: data?.name,
      mobile_number: data?.mobile_number,
      flat_home_bldg: data?.flat_home_bldg,
      col_land_strt: data?.col_land_strt,
      country_id: data?.country_id?.value,
      state_id: data?.state_id?.value,
      city_id: data?.city_id?.value,
      pincode_id: data?.pincode_id?.value,
    }

    if(data?.id){
      sendData.id = data?.id;
    }
    const res = await postAddress(sendData);
    if (res?.success) {

      setModalShow(true);

      setTimeout(() => {
          
      setModalShow(false);
      }, 2000);
      hideModalWithDelay();
      addressGet();
      reset();
      setValue('address_type', "");
      setValue('name', "");
      setValue('flat_home_bldg', "");
      setValue('col_land_strt', "");
      setValue('mobile_number', "");
      setValue('mobile_number', "");
    setValue('country_id',"");
    setValue('state_id', "");
    setValue('pincode_id',"");
    setValue('address_type', "");
    setValue('id', "");
    }
  }

  useEffect(() => {
    getCountries();
    addressGet();
  }, []);
  return (
    <>
      <section className="Account_Address my-account ">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/my-account"}>My account</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <Link to={""}>Address </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="">
            <h2 className="title">My Address</h2>
          </div>

          <div className="checkout-inner-steps">
            <div className="container">
              <div className="address-holders">
                <Form>
                  <div className="row mb-4">
                    <div className="col-lg-6  col-md-12 right_border">
                      <div>
                        <div className=" radio_input">
                          <div className="row">
                            {address?.map((value, index) => (
                              <div className="col-md-6">
                                <div className="radio_input_field">
                                  <div className="heading-text-holder">
                                    <div>
                                      <h3>{value?.address_type} </h3>
                                    </div>

                                    <div>
                                      <FontAwesomeIcon
                                        className="icon-img"
                                        icon={faPen}
                                        // onClick={() => {
                                        //   setModalEditAddressShow(true);
                                        // }}
                                        onClick={()=>handleEdit(value?.id)}
                                      />

                                      <FontAwesomeIcon
                                        className="icon-img"
                                        icon={faTrash}
                                        onClick={() => {setAddId(value?.id); setModalDeleteAddressShow(true);}}
                                       
                                        
                                      />
                                    </div>
                                  </div>
                                  <div className="info-holder">
                                    <div className="text-holder">
                                      <FontAwesomeIcon
                                        className="icon-img"
                                        icon={faLocationDot}
                                      />
                                      <p>
                                        {value?.name + "," + value?.flat_home_bldg + "," + value?.col_land_strt + "," + value?.state?.name + "," + value?.city?.name + "," + value?.pincode?.name}
                                      </p>
                                    </div>

                                    <div className="text-holder">
                                      {" "}
                                      <FontAwesomeIcon
                                        className="icon-img"
                                        icon={faPhone}
                                      />{" "}
                                      <p>{value?.mobile_number}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                            {address.length === 0 && (
                                <h3>No Data Found</h3>
                            )}

                            {/* <div className="col-md-6">
                              <div className="radio_input_field">
                                <div className="heading-text-holder">
                                  <div>
                                    <h3>Office</h3>
                                  </div>

                                  <div>
                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faPen}
                                      onClick={() => {
                                        setModalEditAddressShow(true);
                                      }}
                                    />

                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faTrash}
                                      onClick={() => {
                                        setModalDeleteAddressShow(true);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="info-holder">
                                  <div className="text-holder">
                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faLocationDot}
                                    />
                                    <p>
                                      1959/1, Kucha Chelan, Darya Ganj, Delhi -
                                      110002
                                    </p>
                                  </div>

                                  <div className="text-holder">
                                    {" "}
                                    <FontAwesomeIcon
                                      className="icon-img"
                                      icon={faPhone}
                                    />{" "}
                                    <p>+91 9956128564</p>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6  col-md-12 left_border">
                      <div className="main-heading-text">
                        <div className="heading-holder">
                          <h4 className="form-check-label ">Add new address</h4>
                        </div>
                      </div>

                      <Form>
                        <div>
                          <div className="address_input">
                            <div className="flex-radio-input">
                              <Form.Label>Name*</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Name"
                                {...register("name", {
                                  required: "Name is required",
                                  pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: "Name should only contain alphabetic characters",
                                  },
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.name,
                                  "is-valid": getValues("name"),
                                })}
                              />
                            </div>
                            {errors.name && (
                              <span className="text-danger"
                                style={{ marginLeft: "160px" }}>
                                {errors.name.message}</span>
                            )}
                          </div>
                          <div className="address_input">
                            <div className="flex-radio-input">
                              <Form.Label>Mobile number*</Form.Label>
                              <Form.Control
                                className={classNames("", {
                                  "is-invalid": errors?.mobile_number,
                                  "is-valid": getValues("mobile_number"),
                                })}
                                type="text"
                                name="mobile_number"
                                placeholder="Enter Mobile Number"
                                {...register("mobile_number", {
                                  required: "Contact Number is required",
                                  minLength: {
                                    value: 10,
                                    message: "Number should be at least 10 characters",
                                  },
                                })}
                                onKeyDown={(event) => {
                                  if (!RegxExpression.phone.test(event.key)) {
                                    if (event.key !== "Backspace") {
                                      event.preventDefault();
                                    }
                                  }
                                }}
                                maxLength={10}
                              />
                            </div>
                            {errors.mobile_number && (
                              <span className="text-danger"
                                style={{ marginLeft: "160px" }}>
                                {errors.mobile_number.message}</span>
                            )}
                          </div>
                          <div className="address_input">
                            <div className="flex-radio-input">
                              <Form.Label>Name of address*</Form.Label>
                              <div className="address-name-select">
                                <Controller
                                  name="address_type"
                                  {...register("address_type", {
                                    required: "Select Address Type",
                                  })}
                                  control={control}
                                  render={({ field }) => (
                                    <Select

                                      styles={{
                                        control: (baseStyles) => ({
                                          ...baseStyles,
                                          borderColor: errors.address_type
                                            ? "red"
                                            : baseStyles,
                                        }),
                                      }}
                                      {...field}
                                      options={options}
                                    />
                                  )}
                                />
                                {/* <Select
                            classNamePrefix={"selectOptions"}
                            options={options}
                            placeholder="--- please select ---"
                          /> */}
                              </div>

                            </div>
                            {errors.address_type && (
                              <span className="text-danger"
                                style={{ marginLeft: "160px" }}>
                                {errors.address_type.message}</span>
                            )}
                          </div>

                          <div className="address_input">
                            <div className="flex-radio-input">
                              <Form.Label>Flat no./House/bldg*</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Flat no./House/bldg"
                                {...register("flat_home_bldg", {
                                  required: "Flat no./House/bldg required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.flat_home_bldg,
                                  "is-valid": getValues("flat_home_bldg"),
                                })}
                              />
                            </div>
                            {errors.flat_home_bldg && (
                              <span className="text-danger"
                                style={{ marginLeft: "160px" }}>
                                {errors.flat_home_bldg.message}</span>
                            )}
                          </div>

                          <div className="address_input">
                            <div className="flex-radio-input">
                              <Form.Label>Colony/street/landmark</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Colony/street/landmark"
                                {...register("col_land_strt", {
                                  required: "Colony/street/landmark required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.col_land_strt,
                                  "is-valid": getValues("col_land_strt"),
                                })}
                              />
                            </div>
                            {errors.col_land_strt && (
                              <span className="text-danger"
                                style={{ marginLeft: "160px" }}>
                                {errors.col_land_strt.message}</span>
                            )}
                          </div>

                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-md-6 col-sm-6 mb-2">
                            <Controller
                              name="country_id"
                              {...register("country_id", {
                                required: "Select Country",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select

                                  placeholder="Country"
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.country_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={countries}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    getState(selectedOption.value);
                                    setValue("country_id", selectedOption);
                                  }}
                                />
                              )}
                            />

                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6 mb-2">
                            <Controller
                              name="state_id"
                              {...register("state_id", {
                                required: "Select Country",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  placeholder="State"
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.state_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={states}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    getCity(selectedOption.value);
                                    setValue("state_id", selectedOption);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6 mb-2">
                            <Controller
                              name="city_id"
                              {...register("city_id", {
                                required: "Select Country",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  placeholder="City"
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.city_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={cities}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    getPincode(selectedOption.value);
                                    setValue("city_id", selectedOption);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6 mb-2">
                            <Controller
                              name="pincode_id"
                              {...register("pincode_id", {
                                required: "Select Country",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  placeholder="Pincode"
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.pincode_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={pincodes}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Form>
              </div>

              <div className="main-btn-div ">
                <Common_Button
                  text="Save Address "
                  className="SubmitBtn"
                  padding={"8px 50px"}
                  onClick={handleSubmit(onSubmit)}
                  // onClick={() => {
                  //   setModalShow(true);
                  //   hideModalWithDelay(); // Call hideModalWithDelay function when showing modal
                  // }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Successfull_Modal
        message="Data saved successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Edit_Address_Modal
        show={modalEditAddressShow}
        onHide={() => setModalEditAddressShow(false)}
      />

      <Delete_Address
        message="Are you sure you want to delete address ? "
        show={modalDeleteAddressShow}
        onHide={() => setModalDeleteAddressShow(false)}
        addressDelete={addressDelete}
        addId={addId}

      />
    </>
  );
}

export default AddAddress;
