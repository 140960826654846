import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
} from "../../api";

export const sendMobileOTP = async (contact_no) => {
    try {
        const res = await postData(`/without-login/otp/mobile-otp-send`, {
            contact_no,
        });

        return res;
    } catch (error) {
        console.log(error);
    }
};

export const verifyMobileOTP = async (data) => {
    try {
        const res = await postData(`/without-login/otp/mobile-verify-otp`, data);
        return res;
    } catch (error) {
        console.log(error);
    }
};
