import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";
  
  export const Register = async (data) => {
    try {
      const res = await postData(`/auth/register`,data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  
  export const Login = async (data) => {
    try {
      const res = await postData(`/auth/login`,data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  