import React, { useState, useEffect, useContext } from "react";
import './AboutBanner.css'
import { Context } from "../../../utils/context";
import { bannerLelo } from "../../../utils/apis/common/Common";
const AboutBanner = () => {

    const [getBanner, setBanner] = useState();
    const { getData, IMG_URL } = useContext(Context);
    const getProject = async () => {
        const res = await bannerLelo();
        if (res?.success) {
            setBanner(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getProject();
    }, []);
    return (
        <>
            <section className='about-banner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 mx-auto mt-4'>
                            {/* <img src={process.env.PUBLIC_URL + "/assets/Image/about/about-banner.png"} className="about-banner" /> */}
                            <img src={IMG_URL + getBanner?.image} className="about-banner" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutBanner