import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import Form from "react-bootstrap/Form";
import "./RateAndReview.css";
import Successfull_Modal from "../../Common_modal/Successfull_Modal/Successfull_Modal";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";

import {
  allProductReviewGet,
  productReviewGet,
  productReviewPost,
} from "../../../utils/apis/Product/Product";
import { Context } from "../../../utils/context";
const RateAndReview = ({ product }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [ratings, setRatings] = useState([1, 0, 0, 0, 0]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleStarClick = (starIndex) => {
    const newRatings = [...ratings];
    var rate = 1;
    for (let i = 0; i < newRatings.length; i++) {
      newRatings[i] = i < starIndex ? 1 : 0;
    }
    newRatings?.map((value, index) => {
      if (value == 1) {
        rate = index + 1;
      }
    });

    setRatings(newRatings);
    setValue("ratings", rate);
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      ratings: 1,
    },
  });

  const onSubmit = async (data) => {
    console.log(data);
    const sendData = new FormData();
    sendData.append("ratings", data?.ratings);
    sendData.append("review_message", data?.review_message);
    sendData.append("product_id", product?.id);

    console.log(product?.id);
    if (selectedFiles.length > 0) {
      selectedFiles?.map((image, index) => {
        sendData.append(`images[${index}]`, image);
      });
    }
    const res = await productReviewPost(sendData);
    if (res?.success) {
      getProductReview();
      getUserReview();
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
      }, 3000);
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveImage = (index) => {
    const filteredFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(filteredFiles);
  };

  const renderStars = () => {
    return ratings.map((rating, index) => (
      <FontAwesomeIcon
        key={index}
        icon={rating === 1 ? solidStar : regularStar}
        className="star-icon me-1"
        onClick={() => handleStarClick(index + 1)}
      />
    ));
  };

  const { IMG_URL } = useContext(Context);
  const [reviews, setReviews] = useState([]);
  const getProductReview = async () => {
    console.log(product?.id);
    const res = await allProductReviewGet(product?.id);
    if (res?.success) {
      setReviews(res?.data);
    }
  };

  const [userreview, setUserReviews] = useState(false);
  const getUserReview = async () => {
    console.log(product?.id);
    const res = await productReviewGet(product?.id);

    setUserReviews(res?.data?.hasReviewed);
  };

  useEffect(() => {
    getProductReview();
    getUserReview();
  }, [product]);

  return (
    <>
      <section className="rate-review">
        <div className="container">
          <div className="row">
            {!userreview && (
              <>
                <div className="rate-holder text-center mb-3">
                  <p>Rate & Review</p>
                </div>
                <div className="rating pt-3">
                  <p>Rating ({getValues("ratings")}/5)</p>
                  <div className="">{renderStars()}</div>
                  <p className="mt-4">Add Images / Videos</p>
                  <div className="d-flex mt-3">
                    <Form.Label className="plus-btn me-5">
                      <FontAwesomeIcon icon="plus" />
                      <Form.Control
                        type="file"
                        onChange={handleFileChange}
                        multiple
                        accept="image/*,video/*"
                      />
                    </Form.Label>
                    <div className="image-holder">
                      {selectedFiles.length > 0 &&
                        selectedFiles.map((file, index) => (
                          <div className="file-img-holder" key={index}>
                            {/* <img
                              className="img-logo"
                              src={URL.createObjectURL(file)}
                              alt={`Uploaded File ${index + 1}`}
                            /> */}
                            <object
                              className="img-logo"
                              data={URL.createObjectURL(file)}
                              type={file.type}
                              width="59"  // Set the width
                              height="58" // Set the height
                            />
                            <div
                              className="cross-overlay"
                              onClick={() => handleRemoveImage(index)}
                            >
                              <img
                                className="img-cross"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Image/Icon/cross-icon.png"
                                }
                                alt={`Cross Icon ${index + 1}`}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="form-holder mb-lg-0 mb-3 mt-3">
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>What did you like?</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="review_message"
                        type="text"
                        rows={3}
                        placeholder="Enter Message"
                        {...register("review_message", {
                          required: "Message required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.review_message,
                        })}
                      />
                    </Form.Group>
                    <div className="button-holder text-md-end text-center">
                      <Common_Button
                        text=" Cancel"
                        onClick={() => {
                          setRatings([1, 0, 0, 0, 0]);
                          setSelectedFiles([]);
                          reset();
                        }}
                        className="btn-cancle me-2"
                      />
                      <Common_Button
                        onClick={handleSubmit(onSubmit)}
                        text="post"
                        className="btn-post me-2"
                      />
                    </div>
                  </Form>
                </div>
              </>
            )}

            <div className="top-review">
              <h2>Top 3 Reviews</h2>
            </div>
            <Swiper
              className="mySwiper"
              modules={[Pagination]}
              pagination={{
                dynamicBullets: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                420: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                486: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
            >
              {reviews?.map((review) => (
                <SwiperSlide key={review?.id}>
                  <div className="review-card mb-5">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="date-holder">
                          <span>
                            {new Date(review?.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                              }
                            )}
                          </span>
                        </div>
                        <div className="name-text mt-3">
                          <h3>{review?.user?.first_name}</h3>
                          <p>{review?.review_message}</p>
                          <div className="">
                            {[...Array(parseInt(review?.ratings))].map(
                              function (i) {
                                return (
                                  <FontAwesomeIcon
                                    icon={solidStar}
                                    className="star-icon me-1"
                                  />
                                );
                              }
                            )}
                            {[...Array(5 - parseInt(review?.ratings))].map(
                              function (i) {
                                return (
                                  <FontAwesomeIcon
                                    icon={regularStar}
                                    className="star-icon me-1"
                                  />
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="img-holder text-center">
                          {review?.product_reviews_images?.map((image) => (
                            // <img
                            //   className="img-card"
                            //   src={IMG_URL + image?.image}
                            // />
                            <object
                              className="img-card"
                              data={IMG_URL + image?.image}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {/* {reviews.map((review) => (
              <div key={review.id} className="col-lg-4 col-md-6 mb-3">
                <div className="review-card ">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="date-holder">
                        <span>{review.date}</span>
                      </div>
                      <div className="name-text mt-3">
                        <h3>{review.name}</h3>
                        <p>{review.content}</p>
                        <div className="">
                          {renderStars()} 
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="img-holder text-center">
                        <img
                          className="img-card"
                          src={review.imageUrl}
                          alt={`Reviewer ${review.id}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </section>
      <Successfull_Modal
        message=" Review added successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default RateAndReview;
