import React from "react";
import { Link } from "react-router-dom";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
const EmptyWishlist = () => {
  return (
    <>
      {/* empty cart section start */}
      <section className="Empty_Cart">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-5 col-md-6 col-sm-7 col-10">
              <div className="shop-now-content">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Image/Cash_On_Delivery_Process/basket.png"
                    }
                    className="basket_img"
                  />
                </div>
                <div className="heading_holder mt-3">
                  <h5>Your Wishlist is Empty</h5>
                  <p> Explore more and shortlist some items</p>
                  <div className="main-btn-div">
                    <Link to={"/"}>
                      <Common_Button
                        className=" back-btn"
                        text=" Start Shopping"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* empty cart section end */}
    </>
  );
};

export default EmptyWishlist;
