import React, { useState, useEffect, useContext } from "react";
import "./Coupan.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

import { Coupons } from "../../utils/apis/master/Master";
import { Context } from "../../utils/context"
import { Link } from "react-router-dom";
const Coupan = () => {
    // const coupans = [
    //     { id: 1, title: "Filon Asset Store", amt: "$50.000", code: "MAS500" },
    //     { id: 2, title: "Filon Asset Store", amt: "$50.000", code: "MAS600" },
    //     { id: 3, title: "Filon Asset Store", amt: "$50.000", code: "MAF500" },
    //     { id: 4, title: "Filon Asset Store", amt: "$50.000", code: "MAA500" },
    // ];

    const handleCopy = (code) => {
        navigator.clipboard.writeText(code)
    };


    const { IMG_URL } = useContext(Context);
    const [coupons, setCoupons] = useState([]);
    const getCoupons = async () => {
        const res = await Coupons();
        if (res?.success) {
            setCoupons(res.data);
        }
    }
    useEffect(() => {
        getCoupons();
    }, []);

    return (
        <section className='Coupan'>
            <div className='container'>
                <div className='coupancardMain'>
                    <div className='headingtxt'>
                        <h3>All Coupons</h3>

                    </div>
                    <div className='row'>
                        {coupons.map((item, index) => (
                            <div key={index} className='col-xxl-4 col-xl-4 col-lg-4 col-md-6'>
                                <div className='card coupancard'>
                                    <div className='card-body'>
                                        <div className='card-details'>
                                            <h3>{item.name}</h3>
                                            {/* <p>
                                                <Link to={'/product'} >
                                                    Visit Store
                                                </Link>
                                            </p> */}
                                        </div>
                                        {item?.type === "percentage" ? (<p> <b>{item.discount}%</b>
                                            <br></br> get {item.discount}% off on the minmum order of {item?.minimum_order} </p>

                                        ) : (<p> <b>₹. {item.discount}</b>
                                            <br></br>get ₹. {item.discount} off on the minmum order of {item?.minimum_order}</p>)}

                                        <div className='border-card'></div>
                                        <div className='main-images'>
                                            <div className='image'>
                                                <img
                                                    className="card-img"
                                                    src={
                                                        IMG_URL + item?.image
                                                    }
                                                    alt="Logo"
                                                />
                                            </div>


                                        </div>
                                        <div className='coupancode'>
                                            <p className='codetxt'>
                                                Code: <span className='me-2'>{item.code}</span>
                                                <span>
                                                    <FontAwesomeIcon
                                                        icon={faCopy}
                                                        onClick={() => handleCopy(item.code)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section >
    );
};

export default Coupan;
