import React from 'react'
import HomeBanner from './HomeBanner/HomeBanner'
import ShopBySeller from './ShopBySeller/ShopBySeller'
import PopularCategories from './PopularCategories/PopularCategories'
import OurProduct from './OurProduct/OurProduct'
import SelectByCategories from './SelectByCategories/SelectByCategories'

const HomePage = () => {
    return (
        <>
            <HomeBanner />
            <ShopBySeller />
            <PopularCategories />
            <OurProduct />
            <SelectByCategories />
        </>
    )
}

export default HomePage