import React, { useContext, useEffect, useState } from "react";
import "./AllProductDetails.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Veggies_Section_Card from "../../../Common-Component/Veggies_Section_Card/Veggies_Section_Card";
import { Prev } from "react-bootstrap/esm/PageItem";
import Product_Card from "../../../Common-Component/Product_Card/Product_Card";
import Popular_Product_Card from "../../../Common-Component/Popular_Product_Card/Popular_Product_Card";


import { ChildSubCategory, Products } from "../../../../utils/apis/Product/Product";
import { Context } from "../../../../utils/context";
const AllProductDetails = ({ sellerData, subCategory }) => {



  const { IMG_URL } = useContext(Context);

  const [activeCategory, setActive] = useState("");
  const [childCategories, setChildCategories] = useState([]);
  const getChildCategory = async () => {
    const res = await ChildSubCategory(subCategory?.id);
    if (res?.success) {
      setChildCategories(res?.data);
      if (res?.data.length > 0) {
        getProducts(res?.data[0]?.id);
      }
    }
  }
  const [products, setProduct] = useState([]);
  const getProducts = async (id) => {
    const res = await Products("", "", id, sellerData?.id);
    if (res?.success) {
      setActive(id);
      setProduct(res?.data?.data);
    }
  }


  useEffect(() => {
    getChildCategory();
  }, [subCategory]);
  return (
    <>
      <section className="all-product-details">
        <Container>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/product">{sellerData?.company_name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{subCategory?.name}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="heading-holder mt-5 mb-4">
              <h2 className="title ">{subCategory?.name}</h2>
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="mb-5 me-0 ms-0 select-categories">
                <Nav variant="pills" className="flex-row p-0">
                  <Swiper
                    className="mySwiper1"
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      420: {
                        slidesPerView: 2,
                      },
                      486: {
                        slidesPerView: 2,
                      },
                      768: {
                        slidesPerView: 3,
                      },
                      992: {
                        slidesPerView: 3,
                      },
                      1200: {
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {childCategories?.map((slide, index) => (
                      <SwiperSlide key={index} className="product-slide">
                        <Nav.Item>
                          <Nav.Link className={activeCategory === slide?.id ? "active" : ""} onClick={() => getProducts(slide?.id)}>
                            <Popular_Product_Card
                              className="product-image"
                              content={slide?.name}
                              alt={slide?.name}
                              image={IMG_URL + slide?.image}
                              imgClassName="category-image"
                            />
                            <div className="triangle-right"></div>
                          </Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Nav>
              </Row>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    {products?.map((product, index) => (
                      <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6}>

                        <div className="mb-3">
                          {/* <Product_Card /> */}
                          <Product_Card
                            image={IMG_URL + product?.image1}
                            product_id={product?.id}
                            text={product?.name}
                            subText={product?.short_description}
                            price={product?.s_price}
                          />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">Exotic Vegetables</Tab.Pane>
                <Tab.Pane eventKey="third">Herbs & Seasoning</Tab.Pane>
                <Tab.Pane eventKey="fourth">Sprouts</Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AllProductDetails;
