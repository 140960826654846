import React, { useState } from "react";
import { Table } from "react-bootstrap";

import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useRazorpay from "react-razorpay";

import { useNavigate } from "react-router-dom";


import { Checkout } from "../../../../utils/apis/orderProcess/orderProcess";
import Cart from "../../Cart/Cart";

const ThreeOrder = ({ handlePrevious, paymentMethod, addressId }) => {
  const [modalShow, setModalShow] = useState(false);
  const [numberTwo, setNumberTwo] = useState(1);
  const [numbOne, setNumberOne] = useState(1);

  const navigate = useNavigate();


  console.log("paymentMethod", paymentMethod);

  const hideModalWithDelay = () => {
    setTimeout(() => {
      setModalShow(false);
      navigate('/');
    }, 3000);
  };



  //Payment Gateway

  const [Razorpay, openRazorpay] = useRazorpay();
  const [razorid, setRazorId] = useState();
  const [amount, setAmount] = useState();
  const [tax, setTotalTax] = useState();
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const handleRazorpay = async (storedValue) => {
    console.log(storedValue, "ghjgfjh");
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(storedValue) * 100,
      currency: "INR",
      name: "Hiclousia",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(res.razorpay_payment_id);
        console.log(res, "ttttttttttttttttttttttttttttt");
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };


  const finalFunction = async (razor = null) => {
    const data = {
      address_id: addressId,
      amount: amount,
      discount: discount,
      tax: tax,
      payment_mode: razor ? 'online' : 'cod',
    }
    if (coupon) {
      data.code_id = coupon;
    }
    if (razor) {
      data.payment_id = razor;
    }
    const res = await Checkout(data);
    if (res?.success) {
      setModalShow(true);
      hideModalWithDelay();
    }
  }

  return (
    <>
      <section className="checkout-inner-steps">
        <div className="container">
          <div className="confirm_order Product_Cart threeorder">
            <Cart type={'checkout'} amount={amount} setAmount={setAmount} discount={discount} setDiscount={setDiscount} coupon={coupon} setCoupon={setCoupon} tax={tax} setTotalTax={setTotalTax} />
          </div>
          <div className="main-btn-div ">
            <Common_Button
              onClick={handlePrevious}
              text="Back"
              className=" back-btn"
            />

            {paymentMethod == 'cod' ? (
              <Common_Button
                onClick={() => finalFunction()}
                // onClick={() => {
                //   setModalShow(true);
                //   hideModalWithDelay();
                // }}
                text="Confirm Order"
                className=" save-btn"
              />
            ) : (
              <Common_Button
                onClick={() => handleRazorpay(amount)}
                text="Confirm Order"
                className=" save-btn"
              />
            )}

          </div>
        </div>
      </section>

      <Successfull_Modal
        message=" Your order has been placed successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ThreeOrder;
