import React from "react";
import "./components/Common_css/Swiper_Slider/Swiper_Slider.css"
import "./App.css";
import "./index.css";
import { useLocation } from 'react-router-dom';
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";
import HomePage from "./components/HomePage/HomePage";
import Product from "./components/product/Product";
import VendorPage from "./components/VendorPage/VendorPage";
import VendorProduct from "./components/VendorProduct/VendorProduct";
import LogIn from "./components/Log-in/LogIn";
import AboutUs from "./components/About-us/AboutUs";
import ContactUs from "./components/contact-us/ContactUs";
import Blog from "./components/Blog/Blog";
import BlogDetails from "./components/Blog/Blog-details/BlogDetails";
import BecomeASeller from "./components/become-a-seller/BecomeASeller";
import NearMe from "./components/Near-me/NearMe";
import Product_Cart from "./components/Product_Cart/Product_Cart";
import CheckoutProcess from "./components/Product_Cart/CheckoutProcess/CheckoutProcess";
import Shop_Now from "./components/Product_Cart/Shop_Now/Shop_Now";
import EmptyWishlist from "./components/Wishlist/Empty-wishlist/EmptyWishlist";
import Wishlist from "./components/Wishlist/Wishlist";
import MyAccount from "./components/MyAccount/MyAccount";
import OrderHistory from "./components/MyAccount/OrderHistory/OrderHistory"
import AccountInfo from "./components/MyAccount/AccountInfo/AccountInfo"
import ChangePassword from "./components/MyAccount/ChangePassword/ChangePassword"
import Forget_Password from "./components/Log-in/Forget_Password/Forget_Password";
import Register_Form from "./components/Log-in/Register_Form/Register_Form";
import ViewAllProduct from "./components/product/View-all-product/ViewAllProduct";
import Faq from "./components/Faq/Faq";
import OrderReturn from './components/MyAccount/Account/Order-return/OrderReturn'
import OrderReturnHistory from "./components/MyAccount/OrderHistory/order-return-history/OrderReturnHistory";
import AppContext from "./utils/context";
import ViewAllScanMart from "./components/Near-me/Viewall-Scanmart/ViewAllScanMart";
import SelectFromCategories from "./components/SelectFromCategories/SelectFromCategories";
import Coupan from "./components/Coupan/Coupan";
import AddAddress from "./components/MyAccount/AddAddress/AddAddress";
import Privacy from "./components/Privacy/Privacy";
import Terms from "./components/Term/Term";
function App() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  return (
    <>

      <AppContext>
        <ScrollToTop />
        <div>
          {!isLoginPage && <Header />}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/product" element={<Product />} />
            <Route path="/vendor-page" element={<VendorPage />} />
            <Route path="/product-detail" element={<VendorProduct />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog-details/:id" element={<BlogDetails />} />
            <Route path="/become-seller" element={<BecomeASeller />} />
            <Route path="/near-me" element={<NearMe />} />
            <Route path="/product-cart" element={<Product_Cart />} />
            <Route path="/product-cart-details" element={<CheckoutProcess />} />
            <Route path="/shop-now" element={<Product_Cart />} />
            <Route path="/empty-wislist" element={<EmptyWishlist />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/order-history" element={<OrderHistory />} />
            <Route path="/address" element={<AddAddress />} />
            <Route path="/information" element={<AccountInfo />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/forget-password" element={<Forget_Password />} />
            <Route path="/register-form" element={<Register_Form />} />
            <Route path="/view-all-product" element={<ViewAllProduct />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/order-return/:orderId" element={< OrderReturn />} />
            <Route path="/order-return-history" element={< OrderReturnHistory />} />
            <Route path="/view-all-scanmart" element={< ViewAllScanMart />} />
            <Route path="/select-from-category/:id" element={< SelectFromCategories />} />
            <Route path="/coupan" element={< Coupan />} />
          </Routes>
          {!isLoginPage && <Footer />}
        </div>
      </AppContext>
    </>
  );
}

export default App;
