import React from 'react'
import OurStory from './privacy-main/OurStory'

const Privacy = () => {
  return (
    <>
      <OurStory />
    </>
  )
}

export default Privacy