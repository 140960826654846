import React from 'react'
import ForgotPassword from './ForgotPassword/ForgotPassword'

function ChangePassword() {
  return (
    <>
    <ForgotPassword/>
    </>
  )
}

export default ChangePassword