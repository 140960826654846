import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
  } from "../../api";
  
  export const sellerCategoryp = async (id) => {
    try {
      return await postData(`/wishlist/add`,{
        product_id:id
    });
    } catch (error) {
      console.log(error);
    }
  };
  
  export const sellerCategory = async (id) => {
    try {
      return await getData(`/seller/seller-category/${id}`);
    } catch (error) {
      console.log(error);
    }
  };