import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RegxExpression } from "../../../../utils/common";
import Cookies from 'js-cookie';
import { Context } from "../../../../utils/context";
import { useForm, Controller, SubmitHandler } from "react-hook-form";


function Stepthree(props) {

  return (
    <>

      <section className="personal-details-form userForm">
        <div className="details-form-holder">
          <div className="form-container text-center">
            <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" className="trianglealertmark" />
            <p>Please take a note, 00% of gross commission and onboarding fees ₹. 0000/- will be applicable to your business.</p>
          </div>
        </div>

        <div className="col-md-12">
          <div className="text-end apply_now_btn">
            <div className="registerContinueMain">
              <Button onClick={props.prevStep} className="back-btn me-3">
                Back
              </Button>
              <Button className="tabs-btn" type="button" onClick={props.nextStep}>
                Next
              </Button>

            </div>
          </div>


        </div>

      </section>
    </>
  );
}

export default Stepthree;
