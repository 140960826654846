import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

// export const sendMobileOTP = async (contact_no) => {
//   try {
//     const res = await postData(`/seller/verify-otp/mobile-otp-send`, {
//       contact_no,
//     });

//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const postContactUs = async (data) => {
  try {
    return await postData(`/contact/save`, data);
  } catch (error) {
    console.log(error);
  }
};

export const sellerRegistrationForm = async (data) => {
  try {
    return await postData(`/seller/add`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getAllBlog = async () => {
  try {
    return await getData(`/blog/show/`);
  } catch (error) {
    console.log(error);
  }
}

export const Webheader = async () => {
  try {
    return await getData(`/without-login/home/web-header`);
  } catch (error) {
    console.log(error);
  }
}

export const AppSetup = async () => {
  try {
    return await getData(`/without-login/home/app-setup`);
  } catch (error) {
    console.log(error);
  }
}


export const getCount = async () => {
  try {
    return await getData(`/about/counter`);
  } catch (error) {
    console.log(error);
  }
}

export const bannerLelo = async () => {
  try {
    return await getData(`/without-login/about-us/about-banner/allaboutbanner`);
  } catch (error) {
    console.log(error);
  }
}

export const allourstory = async () => {
  try {
    return await getData(`/without-login/about-us/our-story/allourstory`);
  } catch (error) {
    console.log(error);
  }
}

export const getwhychooseus = async () => {
  try {
    return await getData(`/without-login/about-us/why-choose-us/allwhychooseus`);
  } catch (error) {
    console.log(error);
  }
}

export const privacyPolicy = async () => {
  try {
    return await getData(`/without-login/about-us/privacy/`);
  } catch (error) {
    console.log(error);
  }
}

export const getTerms = async () => {
  try {
    return await getData(`/without-login/about-us/terms/`);
  } catch (error) {
    console.log(error);
  }
}

export const getHappyCustomers = async () => {
  try {
    return await getData(`/without-login/about-us/happy-customers/allhappycustomers`);
  } catch (error) {
    console.log(error);
  }
}