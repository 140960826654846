import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import "./Veggies_Section_Card.css";
import Common_Button from "../Common_Button/Common_Button";
import { Link, useNavigate } from "react-router-dom";
import Heart_Component from "../Heart_Component/Heart_Component";

import { AddToCart } from "../../../utils/apis/addToCart/addToCart";

function Veggies_Section_Card({
  image,
  vegetablename,
  weight,
  price,
  offprice,
  detailsLink,
  product,
  setAddCartModal,
  variant,
}) {
  const [likedProducts, setLikedProducts] = useState(false); // State to track liked products
  console.log("product", product);
  const handleLike = () => {
    setLikedProducts(!likedProducts);
  };

  console.log(variant, "safasfsaf");

  const navigate = useNavigate();
  const handleNavigate = async () => {
    localStorage.setItem("product_id", product?.id);
    navigate("/vendor-page");
  };

  const [firstVariant, setFirstVariant] = useState("");
  // useEffect(() => {
  //   if (product.id) {
  //     // setFirstVariant(product[0]?.product_variants[0])
  //   }

  // }, [product]);

  return (
    <div className="veggies-section mb-5">
      <Card className="veggies ">
        <Heart_Component product={product} variant={variant} />
        <div className="image-main" onClick={handleNavigate}>
          <Card.Img src={image} className="electronic-img" />
        </div>

        <Card.Body>
          <p className="card-heading" onClick={handleNavigate}>
            {vegetablename}
          </p>
          <p className="weight">{weight}</p>

          <div className="price" onClick={handleNavigate}>
            <p className="weight1 me-4">₹ {price}</p>
            <p className="weight3">₹ {offprice}</p>
          </div>
          <div className="cart">
            <div className="background cart-btn">
              <Button variant="light" className="button-holder" type="button">
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  onClick={() => {
                    AddToCart({
                      product_id: product?.id,
                      variant_id: variant?.id,
                    });
                    setAddCartModal(true);
                  }}
                />
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Veggies_Section_Card;
