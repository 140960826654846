import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect, useContext } from "react";
import Form from 'react-bootstrap/Form';
import { useForm, Controller } from "react-hook-form";
import { Button } from 'react-bootstrap'
import Select from 'react-select';
import CongratsSuccessmodal from '../../../Common_modal/CongratsSuccessmodal/CongratsSuccessmodal';
import { Context } from "../../../../utils/context";
import Cookies from 'js-cookie';
import { Category } from "../../../../utils/apis/Product/Product"
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useNavigate } from "react-router-dom";

const Stepeight = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        setTimeout(() => {
            Cookies.remove("user_id");
            setShow(false)
            navigate("/")
        }, 7000);
    };
    const days = [
        { value: 1, label: 'Sunday' },
        { value: 2, label: 'Monday' },
        { value: 3, label: 'Tuesday' },
        { value: 4, label: 'Wednesday' },
        { value: 5, label: 'Thursday' },
        { value: 6, label: 'Friday' },
        { value: 7, label: 'Saturday' },
    ];


    const { getData, IMG_URL, Select2Data, postData } = useContext(Context);
    const [user_id, setUserId] = useState("");

    const {
        control,
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
        watch,
    } = useForm();

    const onSubmit = async (data) => {
        console.log("Datadatdat", data);

        const fromData = new FormData();
        if (data?.id) {
            fromData.append("id", data.id);
        }
        fromData.append("user_id", user_id);
        fromData.append("category_id", JSON.stringify(data?.category_id?.map(item => item?.value)));
        fromData.append("day_id", JSON.stringify(data?.day_id?.map(item => item?.value)));
        fromData.append("other_charges", await getValues("other_charges"));
        fromData.append("menu_mrp", await getValues("menu_mrp"));
        fromData.append("open_time", data?.open_time);
        fromData.append("close_time", data?.close_time);

        try {
            const res = await postData("/seller/s-store-details", fromData);
            if (res?.success) {
                // Cookies.set('user_id', res.data.user_id);
                // nextStep()
                handleShow()
            } else {
                // Handle the case when res.success is false
                console.error("Failed to submit data");
            }
        } catch (error) {
            // Handle any errors that occur during the request
            console.error("An error occurred while submitting data:", error);
        }


    };

    const getEditData = async () => {
        const res = await getData(`/seller/s-store-details/${user_id}`);
        if (res?.success) {
            reset(res?.data)
        }
    }


    const [categories, setCategories] = useState([]);
    const getCity = async () => {
        const res = await Category();
        if (res?.success) {
            const data = await Select2Data(res?.data, 'category_id', false);
            setCategories(data);
        }
    }

    useEffect(() => {
        getCity();
        setUserId(Cookies.get('user_id'));
    }, []);

    useEffect(() => {
        getEditData();
    }, [user_id]);

    console.log("user_id", user_id);

    return (
        <>
            <section className='personal-details-form '>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='stepeight'>
                        <div className='packaginghead'>
                            <h3>Packaging Details</h3>
                            <p>Are there any other order / item packaging charges?*</p>
                        </div>

                        <div className='mb-4'>
                            <Form.Check
                                type="checkbox"
                                label='None'
                                onClick={() => setValue("other_charges", "None")}
                            />

                            <Form.Check
                                type="checkbox"
                                label='Item Level'
                                onClick={() => setValue("other_charges", "Item Level")}
                            />

                            <Form.Check
                                type="checkbox"
                                label='Cart Level / Order Level'
                                onClick={() => setValue("other_charges", "Order Level")}
                            />
                        </div>

                        <div className='packaginghead'>
                            <h3>Does your menu have any MRP / Packed Items</h3>
                            <div className='col-lg-6'>
                                <div className='row mb-4'>
                                    <div className='col-lg-6'>
                                        <Form.Check
                                            type="radio"
                                            label='Yes'
                                            name="yesno"
                                            onClick={() => setValue("menu_mrp", 1)}
                                        />
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Check
                                            type="radio"
                                            label='No'
                                            name="yesno"
                                            onClick={() => setValue("menu_mrp", 0)}
                                        />
                                    </div>

                                </div>
                                {/* {getValues("menu_mrp") == "" && <div className="invalid-feedback">{"Field Is Required"}</div>} */}
                            </div>
                        </div>

                        <div className='row itemDetails'>
                            <div className='col-lg-6'>
                                <div className="field-bottom mb-3">
                                    <Form.Label className="required">Item Categories</Form.Label>
                                    <Controller
                                        name="category_id" // name of the field
                                        control={control}
                                        rules={{ required: "Select Categories" }}

                                        render={({ field }) => (
                                            <Select
                                                isMulti
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.category_id ? "red" : baseStyles.borderColor,
                                                    }),
                                                }}
                                                {...field}
                                                options={categories}
                                            />
                                        )}
                                    />
                                    {errors.category_id && (
                                        <span className="text-danger">
                                            {errors.category_id.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="field-bottom mb-3">
                                    <Form.Label className="required">Days</Form.Label>
                                    <Controller
                                        name="day_id" // name of the field
                                        control={control}
                                        rules={{ required: "Select Days" }}

                                        render={({ field }) => (
                                            <Select
                                                isMulti
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.day_id ? "red" : baseStyles.borderColor,
                                                    }),
                                                }}
                                                {...field}
                                                options={days}
                                            />
                                        )}
                                    />
                                    {errors.day_id && (
                                        <span className="text-danger">
                                            {errors.day_id.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="row">

                                <div className="field-bottom mb-3">
                                    <Form.Label className="required">Open Time</Form.Label>
                                    <Controller
                                        name="open_time"
                                        control={control}
                                        rules={{ required: "Open time is required" }}
                                        render={({ field }) => (
                                            <TimePicker
                                                {...field}
                                                onChange={(value) => field.onChange(value)}
                                                value={field.value}
                                                className={errors.open_time ? 'is-invalid' : ''}
                                            />
                                        )}
                                    />
                                    {errors.open_time && (
                                        <span className="text-danger">
                                            {errors.open_time.message}
                                        </span>
                                    )}
                                </div>

                                <div className="field-bottom">
                                    <Form.Label className="required">Close Time</Form.Label>
                                    <Controller
                                        name="close_time"
                                        control={control}
                                        rules={{ required: "Close time is required" }}
                                        render={({ field }) => (
                                            <TimePicker
                                                {...field}
                                                onChange={(value) => field.onChange(value)}
                                                value={field.value}
                                                className={errors.close_time ? 'is-invalid' : ''}
                                            />
                                        )}
                                    />
                                    {errors.close_time && (
                                        <span className="text-danger">
                                            {errors.close_time.message}
                                        </span>
                                    )}
                                </div>

                            </div>
                        </div>


                    </div>
                    <div className="text-end apply_now_btn">

                        <div className="registerContinueMain">
                            <Button onClick={props.prevStep} className="back-btn me-3">
                                Back
                            </Button>
                            <Button
                                className="tabs-btn "
                                // type="button"
                                // onClick={handleShow}
                                type="submit"
                            >
                                Next
                            </Button>

                        </div>
                    </div>
                </Form>

                <CongratsSuccessmodal show={show} handleClose={handleClose} />

            </section>
        </>
    )
}

export default Stepeight