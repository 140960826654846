import React, { useState } from 'react'
import './ViewAllScanMart.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Rating } from "react-simple-star-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Veggies_Section_Card from '../../Common-Component/Veggies_Section_Card/Veggies_Section_Card';
const ViewAllScanMart = () => {
    const [rating, setRating] = useState(0);

    const handleRating = (rate: number) => {
        setRating(rate);
    };

    const StoreDetails = [
        {
            id: 1,
            productname: "Lenovo ",
            weight: "IdeaPad Slim 1",
            price: "₹. 00.00",
            offprice: "₹. 00.00",
            image: process.env.PUBLIC_URL + "/assets/Image/near-me/Computer.png",
            detailsLink: "/vendor-page",
        },
        {
            productname: "LG ",
            weight: "6.5 Kg 5 Star Inverter Direct Drive Fully",
            price: "₹. 00.00",
            offprice: "₹. 00.00",
            image:
                process.env.PUBLIC_URL + "/assets/Image/near-me/washing_machine.png",
        },
        {
            productname: "Samsung ",
            weight: "28L, Convection Microwave Oven with Curd Making",
            price: "₹. 00.00",
            offprice: "₹. 00.00",
            image: process.env.PUBLIC_URL + "/assets/Image/near-me/microweb.png",
        },
        {
            productname: "Apple ",
            weight: "iPhone 13 (128GB) - Midnight",
            price: "₹. 00.00",
            offprice: "₹. 00.00",
            image: process.env.PUBLIC_URL + "/assets/Image/near-me/phone.png",
        },
        {
            productname: "Samsung ",
            weight: "Galaxy Tab S6 Lite 26.31 cm",
            price: "₹. 00.00",
            offprice: "₹. 00.00",
            image: process.env.PUBLIC_URL + "/assets/Image/near-me/tablet.png",
        },
    ];
    
    return (
        <>
            <section className='near-mart-store'>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="online-store online-store-2 text-md-start text-sm-center text-center">
                                <div className="row pt-4">
                                    <Col lg={2} md={3}>
                                        <div className="store-holder">
                                            <img src={process.env.PUBLIC_URL + '/assets/Image/near-me/online-store.png'} className="store-img" />

                                        </div>
                                    </Col >
                                    <Col lg={10} md={9}>
                                        <h2 className="mt-md-0 mt-sm-4 mt-4">ScanMart Store</h2>
                                        <div className="start-holder">
                                            <Rating
                                                onClick={handleRating}
                                                initialValue={rating}
                                                fillColor="#DF4223"
                                                className="react-simple-star-rating start"
                                                SVGclassName="star-svg"
                                            />
                                        </div>
                                        <div className="location-holder my-1">
                                            <FontAwesomeIcon
                                                icon="fa-solid fa-location-dot"
                                                className="location-icon me-1"
                                            />
                                            <span>Pune</span>
                                        </div>
                                        <span className='distance'>5 minutes away from your location</span>
                                    </Col>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        {StoreDetails.map((item, index) => (
                            <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6} >
                                <Veggies_Section_Card
                                    detailsLink={item.detailsLink}
                                    image={item.image}
                                    vegetablename={item.productname}
                                    weight={item.weight}
                                    price={item.price}
                                    offprice={item.offprice}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container >
            </section >
        </>
    )
}

export default ViewAllScanMart