import React, { useState, useContext } from "react";

import { Context } from "../../../utils/context";
import { ShimmerThumbnail } from "react-shimmer-effects";
function Popular_Product_Card({
  content,
  image,
  imgClassName,
  className,
  textClassName,
  alt,
}) {
  const { shimmerLoader, setShimmerLoader } = useContext(Context);

  return (
    <>
      {shimmerLoader ? (
        <ShimmerThumbnail height={300} rounded />
      ) : (
        <div className={className}>
          <img src={image} className={imgClassName} alt={alt} />
          <div className="text-holder">
            <p className={textClassName}>{content}</p>
          </div>
          <div className="bg-overlay"></div>
        </div>
      )}
    </>
  );
}

export default Popular_Product_Card;
