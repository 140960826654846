import React from 'react'
import OurStory from './term-main/OurStory'

const Term = () => {
  return (
    <>
      <OurStory />
    </>
  )
}

export default Term