import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';
import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
} from "../../api";

import { Context, variant } from "../../context";
import { useContext } from "react";

import { getProductDetail, getVariant, } from "../Product/Product";

export const loginCart = async () => {
    let cartItems = [];
    const existingCart = Cookies.get('local_mandai_cart');
    if (existingCart) {
        cartItems = JSON.parse(existingCart);
    }
    if (cartItems.length > 0) {
        await Promise.all(cartItems.map(async (value) => {
            const data = await postData(`/cart/add`, {
                product_id: value?.product_id,
                variant_id: value?.variant_id,
                quantity: value?.quantity
            });
        }));
    }
    await Cookies.remove('local_mandai_cart');
}

export const AddToCart = async (d) => {
    try {
        if (Cookies.get('local_mandai_security')) {
            const res = await postData(`/cart/add`, {
                product_id: d.product_id,
                variant_id: d.variant_id

            });
            return true;
        } else {

            let cartItems = [];

            const existingCart = Cookies.get('local_mandai_cart');
            if (existingCart) {
                cartItems = JSON.parse(existingCart);
            }
            const res = await getVariant(d?.variant_id);
            const res1 = await getProductDetail(d?.product_id);
            if (res?.success) {
                const existingItemIndex = cartItems.findIndex(item => item.product_id === d?.product_id && item.variant_id === d?.variant_id);

                if (existingItemIndex === -1) {
                    const newItem = {
                        id: uuidv4(), // Generate a unique ID
                        product_id: d?.product_id,
                        variant_id: d?.variant_id,
                        name: res1?.data?.name,
                        image: res?.data?.product_images[0]?.image,
                        price: res?.data?.s_price,
                        tax_percent: res1?.data?.product_detail?.tax_percentage?.name,
                        quantity: 1,
                    }
                    cartItems.push(newItem);
                } else {

                    cartItems[existingItemIndex].quantity++;
                }

                Cookies.set('local_mandai_cart', JSON.stringify(cartItems));
            }
        }

        return true;

    } catch (error) {
        console.log(error);
    }
};

export const CartQuantity = async (id, type, variant_id) => {
    try {

        if (Cookies.get('local_mandai_security')) {
            const res = await postData(`/cart/quantity`, {
                cart_id: id,
                type: type,

            });
            return true;
        } else {

            let cartItems = [];

            const existingCart = Cookies.get('local_mandai_cart');
            if (existingCart) {
                cartItems = JSON.parse(existingCart);
            }

            const existingItemIndex = cartItems.findIndex(item => item.id === id);
            if (existingItemIndex === -1) {

            } else {

                if (cartItems[existingItemIndex]?.quantity === 1 && type === 'minus') {
                    cartItems.splice(existingItemIndex, 1);
                } else {
                    if (type === 'minus') {
                        cartItems[existingItemIndex].quantity -= 1;
                    } else {
                        cartItems[existingItemIndex].quantity += 1;
                    }
                }
                Cookies.set('local_mandai_cart', JSON.stringify(cartItems));
            }
        }
        return true;

    } catch (error) {
        console.log(error);
    }
};


export const getCart = async () => {
    try {
        return await getData(`/cart/show`);
    } catch (error) {
        console.log(error);
    }
};

export const getCartCheck = async (data) => {
    try {
        if (Cookies.get('local_mandai_security')) {

            const queryParams = new URLSearchParams(data).toString();
            return await getData(`/cart/getCart?${queryParams}`);

        } else {
            let cartItems = [];
            const existingCart = Cookies.get('local_mandai_cart');
            if (existingCart) {
                cartItems = JSON.parse(existingCart);
            }
            const existingItemIndex = cartItems.findIndex(item => item.product_id == data?.product_id && item.variant_id == data?.variant_id);
            if (existingItemIndex === -1) {
                return { success: false, cartItem: cartItems[existingItemIndex] };
            } else {
                return { success: true, cartItem: cartItems[existingItemIndex] };
            }
        }


    } catch (error) {
        console.log(error);
    }
};

