import React from "react";
import "./Shop_Now.css";
import { Link } from "react-router-dom";

function Shop_Now() {
  return (
    <>
      {/* empty cart section start */}
      <section className="Empty_Cart">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-5 col-md-6 col-sm-7 col-10">
              <div className="shop-now-content">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Image/Cash_On_Delivery_Process/basket.png"
                    }
                    className="basket_img"
                  />
                </div>
                <div className="heading_holder mt-3">
                  <h5>Your cart is Empty</h5>
                  <p>
                    You have no items in your shopping cart. Let's go buy
                    something
                  </p>
                  <div className="main-btn-div">
                    <Link to={"/"}>
                      <button className="back-btn" type="button">Shop Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* empty cart section end */}
    </>
  );
}

export default Shop_Now;
