import React, { useEffect, useState } from 'react'
import ProductDetail from '../VendorProduct/ProductDetail/ProductDetail'
import SecondVendor from '../VendorPage/SecondVendor/SecondVendor'
import RateAndReview from '../VendorPage/RateAndReview/RateAndReview'
import { getProductDetail } from '../../utils/apis/Product/Product';
import { useLocation, useNavigate } from 'react-router-dom';
const VendorProduct = () => {


  const navigate = useNavigate();
  const [product, setProduct] = useState();
  const getProduct = async () => {
    const storedProductId = localStorage.getItem('product_id');
    if (storedProductId) {
      const res = await getProductDetail(storedProductId);
      if (res?.success) {
        setProduct(res?.data);
      }
    } else {
      navigate('/');
    }
  }
  useEffect(() => {
    getProduct();
  }, []);
  return (
    <>
      <ProductDetail product={product} />
      <RateAndReview />
      <SecondVendor heading="Frequently Bought" />
    </>
  )
}

export default VendorProduct
