import React, { useState, useEffect, useContext } from "react";
import './OurStory.css'
import { Context } from "../../../utils/context";
import { allourstory } from "../../../utils/apis/common/Common";
const OurStory = () => {
    const [getOurStory, setOurStory] = useState();
    const { getData, IMG_URL } = useContext(Context);
    const getProject = async () => {
        const res = await allourstory();
        if (res?.success) {
            setOurStory(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getProject();
    }, []);
    return (
        <>
            <section className='our-story'>
                <div className='container'>
                    <div className='row'>
                        <div className="text-center">
                            <h1 className="hot-title">Our Story</h1>
                            <div className="title-border"></div>
                            <p className="sub-text my-4">{getOurStory?.description}</p>
                            <div className="col-md-10 mx-auto">
                                <h5 className="h5-quote">{getOurStory?.name}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurStory