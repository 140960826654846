import React, {useState, useEffect, useContext} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import "./HomeBanner.css";

import { Banners } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context"

const HomeBanner = () => {


  const { IMG_URL } = useContext(Context);
  const [banner ,  setBanner ] = useState([]);
  const getBanner = async () => {
    const res = await Banners();
    if(res?.success){
      setBanner(res.data);
    }
  }
  useEffect(() => {
    getBanner();
  },[]);
  return (
    <>
      <section className="Home-Banner">
        <div className="container-fluid p-0">
          <Swiper
            spaceBetween={30}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            autoplay={{ delay: 300 }}
            className="mySwiper"
          >
            {banner?.map((slide, index) => (
              <SwiperSlide key={index}>
                <img className="Banner-image" src={IMG_URL + slide?.image} alt="Banner" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default HomeBanner;
