import { React, useState, useEffect, useContext } from "react";
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import FirstVendorDetails from './FirstVendorDetails/FirstVendorDetails'
import '../FirstVendor/FirstVendorDetails/FirstVendorDetails.css';
import ProductPage from './ProductPage/ProductPage';
import { Link, useNavigate, } from 'react-router-dom';
import { Context } from "../../../utils/context";
const FirstVendor = ({ product, cartStatus, cartData, getProduct, setCartStatus, setVariantId, setCartData }) => {
    const { variant } = useContext(Context);
    const [selectedVariant, setSelected] = useState([]);
    useEffect(() => {
        setVariantId(selectedVariant?.id)
    }, [selectedVariant]);
    return (
        <section className='first-vendor'>
            <Container>
                <div className='bred-cumb-vendor'>
                    <Breadcrumb className='mt-5'>
                        <Breadcrumb.Item ><Link to="/">Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item >
                            <Link to="/product">{product?.sub_category?.name}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{product?.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Row>
                    <Col xl={4} lg={4} md={12} sm={12}>
                        <ProductPage product={product} selectedVariant={selectedVariant} />
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={12}>
                        <FirstVendorDetails product={product} setCartStatus={setCartStatus}
                            cartStatus={cartStatus} cartData={cartData} getProduct={getProduct}
                            setSelected={setSelected} setCartData={setCartData}
                        // selectedVariant={selectedVariant}
                        />
                    </Col>
                </Row>
                <hr />
            </Container>
        </section>
    )
}

export default FirstVendor