import React, { useEffect, useContext, useState } from 'react'
import ProductBanner from '../product-banner/ProductBanner'
import AllProductDetails from './All-product-details/AllProductDetails'


import { useNavigate } from 'react-router-dom';

import { sellerCategory } from '../../../utils/apis/SellerApi/SellerApi'

import { singleSubCategory } from '../../../utils/apis/Product/Product';
const ViewAllProduct = () => {

  const navigate = useNavigate();

  const [sellerData, setSellerData] = useState();
  const [subCategoryData, setSubCategoryData] = useState();
  const getSubCategory = async () => {
    const storedSellerId = localStorage.getItem('sc_id');

    if (storedSellerId) {
      const res = await singleSubCategory(storedSellerId);

      if (res?.success) {
        setSubCategoryData(res?.data);
      }

    } else {
      // navigate('/');
    }
  }


  const getSeller = async () => {
    const storedSellerId = localStorage.getItem('seller_id');

    if (storedSellerId) {
      const res = await sellerCategory(storedSellerId);

      if (res?.success) {
        setSellerData(res?.data);
      }

    } else {
      // navigate('/');
    }
  }

  useEffect(() => {
    getSeller();
    getSubCategory();
  }, []);
  return (
    <>
      <ProductBanner sellerData={sellerData} />
      <AllProductDetails sellerData={sellerData} subCategory={subCategoryData} />
    </>
  )
}

export default ViewAllProduct