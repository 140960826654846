import { React, useState, useRef, useEffect, useContext } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import "./ProductDetail.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { FullScreen, useFullScreenHandle } from "react-full-screen";


import { Context } from "../../../utils/context";
const ProductDetail = ({ product }) => {
  const handleClose = () => {
    console.log("/vendor-page");
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const handle = useFullScreenHandle();

  // Next prev Button

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };
  const swiperRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  // Zoom image
  const [scale, setScale] = useState(1);
  const [sliderValue, setSliderValue] = useState([0, 100]); // State for the value of RangeSlider

  const handlePlusClick = () => {
    setScale(scale + 0.1);

    // Decrease the value of the slider, ensuring it doesn't go below 0
    const newValue = sliderValue.map((val) => Math.max(val + 100, 0));
    setSliderValue(newValue);
  };

  const handleMinusClick = () => {
    if (scale > 0.1) {
      setScale(scale - 0.1);
    }

    // Decrease the value of the slider, ensuring it doesn't go below 0
    const newValue = sliderValue.map((val) => Math.max(val - 100, 0));
    setSliderValue(newValue);
  };

  const handleMouseDown = () => { };

  const handleMouseUp = () => { };

  const handleMouseMove = () => { };


  const { IMG_URL } = useContext(Context);


  return (
    <>
      <section className="Product-Detail">
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item className="product-detail">
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="product-detail">
              <Link to="/product">{product?.sub_category?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active className="product-detail">
              {product?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
          <FullScreen handle={handle}>
            <div className="background-product">
              <div className="main" onMouseDown={() => false}>
                <div className="product-detail" id="wrapper">
                  <div className="product-detail-main">
                    {selectedImage ? (
                      <img
                        className="product-img"
                        src={selectedImage}
                        alt="Default"
                        style={{ transform: `scale(${scale})` }}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                      />
                    ) : (
                      <img
                        className="product-img"
                        src={
                          IMG_URL +
                          product?.product_images[0]?.image
                        }
                        alt="Default"
                        style={{ transform: `scale(${scale})` }}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                      />
                    )}

                  </div>
                </div>

                <div className="main-section">
                  <div className="toggle">
                    <button
                      onClick={handle.enter}
                      type="button"
                      className="toggle-fullscreen-btn"

                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Vendor-Page/toggle.png"
                        }
                        className="toggle-img"
                      />
                    </button>
                    <div className="toggle-text">
                      <p className="weight">Toggle to Fullscreen</p>
                    </div>
                  </div>

                  <div className="share">
                    <FontAwesomeIcon icon="fa-solid fa-share" />
                    <div className="share-hover">
                      <p className="weight">Share</p>
                    </div>
                  </div>

                  <div className="Buttons" id="buttonWrapper">
                    <div className="minus">
                      <button
                        className="btn btn-minus"
                        type="button"
                        onClick={handleMinusClick}
                      >
                        -
                      </button>
                    </div>
                    <div className="border-line">
                      <RangeSlider
                        className="single-thumb"
                        value={sliderValue} // Pass the value from state
                        onChange={setSliderValue} // Update the state on change
                        thumbsDisabled={[true, false]}
                        rangeSlideDisabled={true}
                      />
                    </div>
                    <div className="plus">
                      <button
                        className="btn btn-plus"
                        type="button"
                        onClick={handlePlusClick}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Link to="/vendor-page">
                {" "}
                <div className="close">
                  <FontAwesomeIcon
                    icon="fa-solid fa-xmark"
                    className="x-mark"
                  />
                </div>
              </Link>
            </div>
          </FullScreen>
          {/* Bootom Part */}
          <div className="row  justify-content-center">
            <div className="col-xxl-4 col-xl-4 col-lg-6  col-md-6 col-12">
              <div className="gallery">
                <Swiper
                  slidesPerView={"auto"}
                  modules={[Navigation, Autoplay]}
                  breakpoints={{
                    0: {
                      slidesPerView: 4,
                      spaceBetween: 60,
                    },
                    380: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    485: {
                      slidesPerView: 5,
                      spaceBetween: 10,
                    },
                    575: {
                      slidesPerView: 5,
                      spaceBetween: 30,
                    },

                    768: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    992: {
                      slidesPerView: 6,
                      spaceBetween: 80,
                    },
                    1024: {
                      slidesPerView: 5,
                      spaceBetween: 10,
                    },
                    1200: {
                      slidesPerView: 5,
                      spaceBetween: 80,
                    },
                    1440: {
                      slidesPerView: 5,
                      spaceBetween: 50,
                    },
                    2000: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
                  navigation
                  pagination={{ clickable: true }}
                  className="mySwiper"
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                >
                  {product?.product_images?.map((value, index) => (
                    <SwiperSlide>
                      <div
                        className="gallery-imgs"
                        onClick={() =>
                          handleImageClick(
                            IMG_URL +
                            value?.image
                          )
                        }
                      >
                        <img
                          src={
                            IMG_URL +
                            value?.image
                          }
                          className="inner-img"
                        />
                      </div>
                    </SwiperSlide>
                  ))}


                  {/* <SwiperSlide>
                    <div
                      className="gallery-imgs"
                      onClick={() =>
                        handleImageClick(
                          process.env.PUBLIC_URL +
                          "/assets/Image/Vendor-Page/cabbage.png"
                        )
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Image/Vendor-Page/cabbage.png"
                        }
                        className="inner-img"
                      />
                    </div>
                  </SwiperSlide> */}


                </Swiper>
                {product?.product_images.length > 5 && (
                  <div class="silder-btn">
                    <div
                      className="back-btn"
                      onClick={() => handleMainSliderClick("prev")}
                    ></div>
                    <div
                      className="next-btn"
                      onClick={() => handleMainSliderClick("next")}
                    ></div>
                  </div>
                )}


              </div>
            </div>
          </div>
          <hr />
        </Container>
      </section>
    </>
  );
};

export default ProductDetail;
