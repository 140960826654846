import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getFaqs = async () => {
  try {
    return await getData(`/without-login/all-faq-categories`);
  } catch (error) {
    console.log(error);
  }
};
export const Banners = async () => {
  try {
    return await getData(`/without-login/home/banner`);
  } catch (error) {
    console.log(error);
  }
};

export const Country = async () => {
  try {
    return await getData(`/without-login/home/get-country`);
  } catch (error) {
    console.log(error);
  }
};

export const State = async (id) => {
  try {
    return await getData(`/without-login/home/get-state/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const City = async (id) => {
  try {
    return await getData(`/without-login/home/get-city/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Cities = async () => {
  try {
    return await getData(`/without-login/allcity`);
  } catch (error) {
    console.log(error);
  }
};

export const Pincode = async (id) => {
  try {
    return await getData(`/without-login/home/get-pincode/${id}`);
  } catch (error) {
    console.log(error)
  }
};


export const SellerGet = async () => {
  try {
    return await getData(`/seller/show`);
  } catch (error) {
    console.log(error)
  }
};

export const NearSellerGet = async (city, category_id) => {
  try {
    let url = `/seller/nearby-seller?city=${city}`;

    if (category_id) {
      url += `&category_id=${category_id}`;
    }
    return await getData(url);
  } catch (error) {
    console.log(error)
  }
};

export const Coupons = async () => {
  try {
    return await getData(`/without-login/home/coupons`);
  } catch (error) {
    console.log(error);
  }
}