import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

import { Coupons } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context"

const CoupanModal = ({ show, handleClose }) => {
    const { IMG_URL } = useContext(Context);
    const [coupons, setCoupons] = useState([]);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        const fetchCoupons = async () => {
            const res = await Coupons();
            if (res?.success) {
                setCoupons(res.data);
            }
        };
        fetchCoupons();
    }, []);

    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            // Text copied successfully
            setCopied(true);
            // Hide "Copied" message after 2 seconds
            setTimeout(() => setCopied(false), 2000);
        })
            .catch((error) => {
                // Handle copy failure
                console.error('Copy failed:', error);
            });
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>All Coupons</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    {coupons.map((item, index) => (
                        <div key={index} className='col-xxl-4 col-xl-4 col-lg-4 col-md-6'>
                            <div className='card coupancard'>
                                <div className='card-body'>
                                    <div className='card-details'>
                                        <h3>{item.name}</h3>
                                    </div>
                                    {item?.type === "percentage" ? (
                                        <p><b>{item.discount}%</b>
                                            <br /> get {item.discount}% off on the minimum order of {item?.minimum_order}
                                        </p>
                                    ) : (
                                        <p><b>₹. {item.discount}</b>
                                            <br /> get ₹. {item.discount} off on the minimum order of {item?.minimum_order}
                                        </p>
                                    )}
                                    <div className='border-card'></div>
                                    <div className='main-images'>
                                        <div className='image'>
                                            <img
                                                className="card-img"
                                                src={`${IMG_URL}${item?.image}`}
                                                alt="Logo"
                                            />
                                        </div>
                                    </div>
                                    <div className='coupancode'>
                                        <p className='codetxt'>
                                            Code: <span className='me-2'>{item.code}</span>
                                            <span>
                                                <FontAwesomeIcon
                                                    icon={faCopy}
                                                    onClick={() => handleCopy(item.code)}
                                                    style={{ cursor: 'pointer' }}
                                                />

                                            </span>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </Modal.Body>
            <Modal.Footer>
                {copied && <span style={{ marginLeft: '5px', alignSelf: "center" }}>Copied</span>}
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CoupanModal;
